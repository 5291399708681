<template>
  <div class="use-time-chart">
    <p class="use-time-chart-title" :class="[
      isShowTitle
        ? 'use-time-chart-title-black'
        : 'use-time-chart-title-white',
    ]">
      使用时间图示
    </p>
    <div class="use-time-chart-x-axis">
      <div class="use-time-chart-x-axis-container">
        <p class="use-time-chart-x-axis-container-item" v-for="(item, index) of xAxisArray" :key="index">
          {{ item }}
        </p>
      </div>
    </div>
    <div class="use-time-chart-content">
      <div class="use-time-chart-content-background">
        <div class="use-time-chart-content-background-line" v-for="(item, index) of 26" :key="index"
          :style="{ marginLeft: `${index == 0 ? 130 : 23}px` }"></div>
      </div>
      <div class="use-time-chart-content-chart">
        <use-time-chart-item v-for="(item, index) of reversedUseTimeArray" :key="index" :model="item"
          :isEven="index % 2 == 0"></use-time-chart-item>
      </div>
    </div>
  </div>
</template>

<script>
import UseTimeChartItem from "@c/report/charts/chart/statistics-chart/use-time-chart/use-time-chart-item.vue";
export default {
  components: {
    UseTimeChartItem,
  },

  props: {
    useTimeArray: Array,
    isShowTitle: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      xAxisArray: [
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
    };
  },

  computed: {
    reversedUseTimeArray() {
      return this.useTimeArray.reverse()
    }
  }
};
</script>

<style lang="scss" scoped>
.use-time-chart {
  background-color: white;
  border: 1px solid #e8e8e8;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;

  &-title {
    font-size: 16px;
    font-weight: 600;
    height: 22px;

    &-black {
      color: black;
    }

    &-white {
      color: white;
    }
  }

  &-x-axis {
    position: relative;
    top: -8px;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    &-container {
      width: 620px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      &-item {
        color: black;
        font-size: 12px;
      }
    }
  }

  &-content {
    position: relative;
    display: flex;

    &-background {
      position: absolute;
      top: 0px;
      font-size: 40px;
      font-weight: 800;
      width: 100%;
      height: 100%;
      display: flex;

      &-line {
        width: 1px;
        height: 100%;
        background-image: linear-gradient(to bottom,
            #c1c1c1 0%,
            #c1c1c1 20%,
            transparent 20%);
        background-size: 1px 8px;
        background-repeat: repeat-y;
      }
    }

    &-chart {
      width: 100%;
    }
  }
}
</style>