<template>
  <div class="statistics-chart">
    <p class="statistics-chart-title">{{ title }}</p>
    <div class="statistics-chart-content" :style="`height: ${225 / zoom}px;`">
      <div :id="title" :style="`width: ${100 / zoom}%; height: ${220/ zoom}px ;zoom:${1 / zoom
        };transform:scale(${zoom});transform-origin:0 0`"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    max: Number,
    dayArray: Array,
    chartDataArray: Array,
  },

  data() {
    return {
      chart: null
    };
  },

  computed: {
    zoom() {
      return this.$store.state.zoom
    },

    yAxis() {
      let data = {};
      data.max = this.max;
      data.axisLine = {
        show: false,
        type: "value",
        lineStyle: {
          color: "#E8E8E8",
        },
      };
      data.axisLabel = {
        show: true,
        color: "#262626",
      };
      data.axisTick = {
        show: false,
      };
      data.splitLine = {
        show: false,
      };
      return data;
    },

    seriesArray() {
      return this.chartDataArray.map((item) => {
        return {
          name: item.name,
          type: item.type,
          step: "middle",
          symbol: "none",
          stack: item.stack,
          itemStyle: {
            color: item.color,
          },
          lineStyle: {
            width: 1,
          },
          data: item.dataArray,
          markLine: this.markLine,
        };
      });
    },

    markLine() {
      switch (this.title) {
        case "使用时长（小时）":
          return {
            symbol: "none",
            data: [
              {
                yAxis: 4,
              },
            ],
            lineStyle: {
              color: "#F45C52",
            },
            label: {
              formatter: (item) => {
                return `${item.value} 小时`;
              },
            },
          };
        case "AHI":
          return {
            symbol: "none",
            data: [
              {
                yAxis: 30,
                lineStyle: {
                  color: "#F45C52",
                },
              },
              {
                yAxis: 15,
                lineStyle: {
                  color: "#F39831",
                },
              },
              {
                yAxis: 5,
                lineStyle: {
                  color: "#0F92F7",
                },
              },
            ],
          };
        case "漏气（L/min)":
          return {
            symbol: "none",
            data: [
              {
                yAxis: 5,
              },
            ],
            lineStyle: {
              color: "#0F92F7",
            },
          };

        default:
          break;
      }
    },
  },

  mounted() {
    this.chart = this.$echarts.init(document.getElementById(this.title));
    this.drawLine();
  },

  watch: {
    dayArray() {
      this.drawLine();
    },
  },

  methods: {
    drawLine() {
      this.chart.setOption({
        legend: {
          right: "0px",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            return this.formatterToolTip(params);
          },
        },
        xAxis: {
          type: "category",
          data: this.dayArray,
          offset: 5,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#E8E8E8",
            },
          },
          axisLabel: {
            show: true,
            color: "#262626",
          },
        },
        yAxis: this.yAxis,
        grid: {
          backgroundColor: "pink",
          left: "30px",
          right: "40px",
          bottom: "2%",
          containLabel: true,
        },
        series: this.seriesArray,
      });

      if (this.title == "湿化器设置") {
        this.chart.setOption({
          yAxis: {
            axisLabel: {
              formatter: (params) => {
                const newParams = params - 1;
                switch (newParams) {
                  case -1:
                    return "";
                  case 0:
                    return "关闭";
                  default:
                    return newParams;
                }
              },
            },
            min: 0,
            max: 6,
          },
        });
      }

      setTimeout(() => {
        window.onresize = () => {
          this.chart.resize();
        };
      }, 200);
    },

    formatterToolTip(params) {
      var tip = "";
      if (params != null && params.length > 0) {
        //title
        tip += params[0].name + "<br />";
        for (let i = 0; i < params.length; i++) {
          //seriesName
          let seriesName = params[i].seriesName;
          switch (params[i].seriesName) {
            case "大于4小时":
            case "小于4小时":
              seriesName = "使用时长";
              break;
            case "大于5（L/min)":
            case "小于5（L/min)":
              seriesName = "漏气";
              break;
            default:
              break;
          }
          //value
          let value = "";
          if (params[i].value == undefined) {
            return "暂无报告";
          } else {
            value = params[i].value;
            switch (params[i].seriesName) {
              case "大于4小时":
              case "小于4小时":
                if (value == 0) {
                  continue;
                }
                break;
              case "大于5（L/min)":
              case "小于5（L/min)":
                if (value == 0) {
                  continue;
                }
                break;
              case "湿化等级":
                const tempValue = value - 1;
                value = tempValue == 0 ? "关闭" : tempValue;
                break;
              default:
                break;
            }
          }
          //unit
          let unit = "";
          switch (params[i].seriesName) {
            case "90%压力趋势":
            case "最大压力设定":
            case "最小压力设定":
              unit = " cmH2O";
              break;
            case "大于4小时":
            case "小于4小时":
              unit = "小时";
              break;
            case "大于5（L/min)":
            case "小于5（L/min)":
              unit = " L/min";
              break;
            default:
              break;
          }
          tip += params[i].marker + seriesName + ": " + value + unit + "<br />";
        }
      }
      return tip;
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics-chart {
  border: 1px solid #e8e8e8;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  &-title {
    color: black;
    font-size: 16px;
  }

  &-content {
    @include z-index-2;
    position: relative;
    top: -18px;
    background-color: transparent;
    width: 100%;
  }
}
</style>