<template>
  <div class="use-time-chart-item">
    <div v-if="newMonthStr" class="use-time-chart-item-head">
      {{ newMonthStr }}
    </div>
    <div class="use-time-chart-item-body" :class="{ gray: isEven }">
      <p class="time time-left" :class="{ 'time-red': isRedReport }">
        {{ reportDateStr }}
      </p>
      <div class="use-time-chart-item-body-content">
        <div v-if="hasData" class="use-time-chart-item-body-content-container">
          <div class="bar" :style="{ marginLeft: `${barLeft}%`, width: `${barWidth}%` }">
            <p v-if="barItemArray.length" class="bar-text">{{ duration }}</p>
            <div v-for="(item, index) of barItemArray" :key="index" class="bar-item"
              :class="[isRedReport ? 'bar-item-red' : 'bar-item-green']" :style="{
                left: `${item.barItemLeft}%`,
                width: `${item.barItemWidth}%`,
              }"></div>
          </div>
        </div>
        <div v-else class="use-time-chart-item-body-content-in-valid">
          没有可用的数据
        </div>
      </div>
      <div class="time time-right">
        <p v-if="barItemArray.length" :class="{ 'time-red': isRedReport }">{{ reportTimeStr }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTool } from "@js/date-tool.js";
export default {
  props: {
    model: Object,
    isEven: Boolean,
  },

  computed: {
    reportDate() {
      return new Date(this.model.day * 1000);
    },

    reportDateStr() {
      const weekDayIndex = this.reportDate.getDay();
      switch (weekDayIndex) {
        case 0:
          return "星期日";
        case 6:
          return "星期六";
        default:
          return DateTool.timestampToStr(this.model.day, "YYYY/MM/DD");
      }
    },

    reportTimeStr() {
      if (this.model.isValid) {
        const startTime = DateTool.timestampToStr(this.model.startTime, "HH:mm");
        const endTime = DateTool.timestampToStr(this.model.endTime, "HH:mm");
        return `${startTime}/${endTime}`;
      } else {
        return "";
      }
    },

    newMonthStr() {
      const weekDate = this.reportDate.getDate();
      return weekDate == 1 //每月1号
        ? DateTool.timestampToStr(this.model.day, "YYYY年MM月")
        : null;
    },

    hasData() {
      return !!this.model.isValid;
    },

    isRedReport() {
      if (this.hasData) {
        return this.model.useTime < 4 * 3600;
      } else {
        return true;
      }
    },

    //bar数据
    duration() {
      return placeholderText(DateTool.formatSeconds(this.model.useTime));
    },

    startPointTimeStamp() {
      return this.reportDate.setHours(12, 0, 0, 0);
    },

    endPointTimeStamp() {
      return this.startPointTimeStamp + 86400 * 1000;
    },

    startTimeStamp() {
      return this.model.startTime * 1000;
    },

    endTimeStamp() {
      return this.model.endTime * 1000;
    },

    barLeft() {
      return (
        ((this.startTimeStamp - this.startPointTimeStamp) /
          (this.endPointTimeStamp - this.startPointTimeStamp)) *
        100
      );
    },

    barWidth() {
      return (
        ((this.endTimeStamp - this.startTimeStamp) /
          (this.endPointTimeStamp - this.startPointTimeStamp)) *
        100
      );
    },

    barItemArray() {
      if (this.model.isValid) {
        //我方的有效报告有时在和普乐后台未生成报告，此时duration为空数组，UI显示要以此为依据来判断
        const rawArray = this.model.duration ?? [];
        return rawArray.map((item) => {
          const itemStartTimeStamp = item[0] * 1000;
          const itemEndTimeStamp = item[1] * 1000;
          const barItemLeft =
            ((itemStartTimeStamp - this.startTimeStamp) /
              (this.endTimeStamp - this.startTimeStamp)) *
            100;
          const barItemWidth =
            ((itemEndTimeStamp - itemStartTimeStamp) /
              (this.endTimeStamp - this.startTimeStamp)) *
            100;
          return {
            barItemLeft,
            barItemWidth,
          };
        });
      } else {
        return [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.use-time-chart-item {
  width: 100%;

  &-head {
    position: relative;
    background-color: white;
    height: 40px;
    color: black;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-body {
    height: 30px;
    box-sizing: border-box;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1px;

    &-content {
      width: 599px;
      height: 100%;

      &-container {
        height: 100%;
      }

      &-in-valid {
        position: relative;
        color: black;
        background-color: #f7f7f7;
        font-size: 14px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.gray {
  background-color: #f7f7f7;
}

.time {
  color: #666666;
  font-size: 14px;
  // flex: 1;
  width: 120px;

  &-left {
    text-align: left;
  }

  &-right {
    text-align: right;
  }

  &-red {
    color: #f45c50;
  }
}

.bar {
  position: relative;
  background-color: transparent;
  color: black;
  font-size: 14px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &-item {
    position: absolute;
    height: 100%;

    &-red {
      background-color: #f45c50;
    }

    &-green {
      background-color: #6cc291;
    }
  }

  &-text {
    @include z-index-2;
    position: absolute;
    width: 100px;
    text-align: center;
  }
}
</style>