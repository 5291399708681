import { DateTool } from "@js/date-tool.js";
import { findKey } from "@js/object-tool.js";

class StatisticsReportModel {
    // constructor
    constructor(model) {
        // super(model)
        this.model = model
        this.basicInfoArray = this._initBasicInfoArray() //基本信息
        this.patientNum = this.model.user.number //病例号
        this.deviceSN = this.model.device.sn
        this.deviceModel = this.model.device.model
        this.abstract = this.model.report.epitomize;
        this.suggestion = this.model.report.advise;
        this.statisticsRange = this._initStatisticsRange()
        this.dependencyArray = this._initDependencyArray()
        this.eventStatisticsArray = this._initEventStatisticsArray() //事件统计
        this.cureDataArray = this._initCureDataArray() //治疗数据
        this.paraArray = this._initParaArray() //治疗参数数据
        this.useTimeArray = this._initUseTimeArray() //使用时长图表数据
        this.chartData = this._initChartData() //图表数据
        this.reportArray = this._initReportArray() //报告数组
    }

    // init
    _initBasicInfoArray() {
        const name = placeholderText(this.model.user?.name);
        const height = placeholderText(this.model.user?.height, "cm");
        const gender = placeholderText(findKey(USER_GENDER, this.model.user?.sex));
        const weight = placeholderText(this.model.user?.weight, "kg");
        const age = placeholderText(this.model.user?.age);
        const BMI = placeholderText(this._computeBMI(height, weight));
        return [
            { title: "姓名", info: name },
            { title: "身高", info: height },
            { title: "性别", info: gender },
            { title: "体重", info: weight },
            { title: "年龄", info: age },
            { title: "BMI", info: BMI },
        ];
    }

    _initStatisticsRange() {
        const timeStrArray = this.model.startEnd.map((item, index) => {
            return DateTool.timestampToStr(item)
        })
        return timeStrArray.join(" ~ ")
    }

    _initDependencyArray() {
        const dayCount = placeholderText(`${this.model.census.dayTotal}`)
        const timeCount = placeholderText(
            DateTool.formatSeconds(this.model.census.longTime)
        )
        const useDayCount = placeholderText(`${this.model.census.dayUse}`)
        const useDayPercent = placeholderText(`${Math.round(useDayCount / dayCount * 100)}`)
        const unUseDayCount = placeholderText(`${this.model.census.dayTotal - this.model.census.dayUse}`)
        const unUseDayPercent = placeholderText(`${100 - useDayPercent}`)

        const over4DayCount = placeholderText(`${this.model.census.longTimeAft}`)
        const over4DayPercent = placeholderText(`${Math.round(over4DayCount / dayCount * 100)}`)
        const under4DayCount = placeholderText(`${this.model.census.longTimePre}`)
        const under4DayPercent = placeholderText(`${Math.round(under4DayCount / dayCount * 100)}`)
        const totalAverageSeconds = parseInt(this.model.census.longTime / dayCount)
        const totalAverageTime = placeholderText(
            DateTool.formatSeconds(totalAverageSeconds)
        )
        const useAverageSeconds = parseInt(this.model.census.longTime / useDayCount)
        const useAverageTime = placeholderText(
            DateTool.formatSeconds(useAverageSeconds)
        )
        const under5Count = placeholderText(`${this.model.census.ahiLt5}`)
        return [
            { title: "总天数", info: `${dayCount}天` },
            { title: "总时长", info: timeCount },
            { title: "已使用天数", info: `${useDayCount}天（${useDayPercent}%）` },
            { title: "未使用天数", info: `${unUseDayCount}天（${unUseDayPercent}%）` },
            { title: "超过4小时天数", info: `${over4DayCount}天（${over4DayPercent}%）` },
            { title: "小于4小时天数", info: `${under4DayCount}天（${under4DayPercent}%）` },
            { title: "平均使用时长（所有天数）", info: totalAverageTime },
            { title: "平均使用时长（已使用天数）", info: useAverageTime },
            { title: "AHI < 5天数", info: `${under5Count}天` },

        ]
    }

    _initEventStatisticsArray() {
        const AHI = placeholderText(this.model.census.ahi);
        const CSA = placeholderText(this.model.census.csa);
        const AI = placeholderText(this.model.census.ai);
        const CSR = placeholderText(this.model.census.csr);
        const HI = placeholderText(this.model.census.hi);
        const PB = placeholderText(this.model.census.pb);
        return [
            { title: "AHI", info: AHI },
            { title: "CSA", info: CSA },
            { title: "AI", info: AI },
            { title: "CSR", info: CSR },
            { title: "HI", info: HI },
            { title: "PB", info: PB },
        ];
    }

    _initCureDataArray() {
        // 90%压力
        const pressure90 = placeholderText(this.model.census.tpIn, "cmH2O");
        // 总时长
        const lastingTime = placeholderText(
            DateTool.formatSeconds(this.model.census.longTime)
        );
        //平均漏气
        const averageLeak = placeholderText(this.model.census.leakage, "L/min");
        //总漏气
        const allLeak = placeholderText(`${this.model.census.totalLeakage}`, "L");
        //心率和血氧返回值为字符串，要求为0时显示“-”，因此需要用parseInt转化
        //平均血氧
        const averageBloodOxygen = placeholderText(parseInt(this.model.census.spoAvg), "%");
        //最低血氧
        const minBloodOxygen = placeholderText(parseInt(this.model.census.spoMin), "%");
        //血氧<90%时长
        const under90Time = placeholderText(
            DateTool.formatSeconds(this.model.census.spoLowTime)
        );
        //平均心率
        const averagePulse = placeholderText(parseInt(this.model.census.bpmMax), "bpm");
        //最大心率
        const maxPulse = placeholderText(parseInt(this.model.census.bpmAvg), "bpm");
        //最小心率
        const minPulse = placeholderText(parseInt(this.model.census.bpmMin), "bpm");
        return [
            {
                title: "90%压力",
                info: pressure90,
            },
            {
                title: "总时长",
                info: lastingTime,
            },
            {
                title: "平均漏气",
                info: averageLeak,
            },
            {
                title: "总漏气",
                info: allLeak,
            },
            {
                title: "平均血氧",
                info: averageBloodOxygen,
            },
            {
                title: "最低血氧",
                info: minBloodOxygen,
            },
            {
                title: "<90%血氧",
                info: under90Time,
                width: 100,
            },
            {
                title: "平均心率",
                info: averagePulse,
                width: 100,
            },
            {
                title: "最大心率",
                info: maxPulse,
            },
            {
                title: "最小心率",
                info: minPulse,
            },
        ];
    }

    _initUseTimeArray() {
        return this.model.list.map((item) => {
            return {
                startTime: item.startTime,
                endTime: item.endTime,
                isValid: item.isValid,
                day: item.dayTime,
                useTime: item.longTime,
                duration: item.quantum
            }
        })

    }

    _initChartData() {
        //array
        const dayArray = this._composeDataArray("day")
        const useHourAbove4Array = this._composeDataArray("useHourAbove4")
        const useHourUnder4Array = this._composeDataArray("useHourUnder4")
        const pressure90Array = this._composeDataArray("tpIn")
        const pressureMaxArray = this._composeDataArray("tpMax")
        const pressureMinArray = this._composeDataArray("tpMin")
        const aiArray = this._composeDataArray("ai")
        const hiArray = this._composeDataArray("hi")
        const csaArray = this._composeDataArray("csa")
        const csrArray = this._composeDataArray("csr")
        const pbArray = this._composeDataArray("pb")
        const humidifyLevelArray = this._composeDataArray("humidifyLevel")
        const leakageAbove5Array = this._composeDataArray("leakageAbove5")
        const leakageUnder5Array = this._composeDataArray("leakageUnder5")
        //maxAhi
        const ahiArray = aiArray.map((item, index) => {
            const ai = item ?? 0
            const hi = hiArray[index] ?? 0
            return parseInt(ai + hi)
        });
        const rawMaxAHI = Math.max(...ahiArray)
        const maxAHI = rawMaxAHI > 40 ? rawMaxAHI : 40
        //maxLeak
        const leakageArray = this.model.list.map((item) => {
            return item.leakage ?? 0
        })
        const rawMaxLeakage = Math.max(...leakageArray)
        const maxLeakage = Math.ceil(rawMaxLeakage / 10) * 10
        return {
            dayArray,
            useHourAbove4Array,
            useHourUnder4Array,
            pressure90Array,
            pressureMaxArray,
            pressureMinArray,
            aiArray,
            hiArray,
            csaArray,
            csrArray,
            pbArray,
            humidifyLevelArray,
            leakageAbove5Array,
            leakageUnder5Array,
            maxAHI,
            maxLeakage
        }
    }

    _initParaArray() {
        const paraDataArray = this.model.parameter.map((item) => {
            //title
            const startDay = DateTool.timestampToStr(item.startTime, "YYYY/MM/DD HH:mm")
            const endDay = DateTool.timestampToStr(item.endTime, "YYYY/MM/DD HH:mm")
            const title = `治疗参数（第${item.startDay}天～第${item.endDay}天）（${startDay}～${endDay}）`
            //paraArray
            const rawParaArray = item.parameter
            const temperatureUnit = rawParaArray?.filter((item) => {
                return item.paramName == "温度单位"
            })[0]?.paramLabel == "摄氏度" ? "°C" : "°F"
            const releasePressureLevel = rawParaArray?.filter((item) => {
                return item.paramName == "释压水平"
            })[0]?.paramLabel
            const paraArray = rawParaArray?.filter((item) => {
                return item.paramName != "温度单位" && item.paramName != "释压水平"
            }).map((item) => {
                switch (item.paramName) {
                    //温度和温度单位合并显示
                    case "加温管温度":
                        return {
                            title: item.paramName,
                            info: `${item.paramLabel}${temperatureUnit}`
                        };
                    //释压和释压水平合并显示
                    case "呼气释压":
                        return {
                            title: item.paramName,
                            info: item.paramLabel == "关闭" ? item.paramLabel : `${item.paramLabel} ${releasePressureLevel}`
                        };
                    default:
                        return {
                            title: item.paramName,
                            info: item.paramLabel
                        };
                }
            })
            return {
                title,
                paraArray
            }
        })
        return paraDataArray
    }

    _initReportArray() {
        var reportArray = this.model.list?.filter((item) => {
            return item.isValid == 1
        }).map((item) => {
            return {
                id: item.reportId,
                createDate: item.day
            }
        })
        reportArray.reverse()
        return reportArray
    }

    // method
    _computeBMI(height, weight) {
        const kgWeight = parseFloat(weight);
        const miterHeight = parseFloat(height) / 100;
        if (kgWeight && miterHeight) {
            return (kgWeight / (miterHeight * miterHeight)).toFixed(1);
        } else {
            return "";
        }
    }

    _composeDataArray(key) {
        return this.model.list.map((item) => {
            switch (key) {
                case "day":
                    return DateTool.timeStrToStr(item[key],'YY/MM/DD')
                // 使用时长
                case "useHourUnder4":
                    if (!item.isValid) {
                        return null
                    } else {
                        const useHour = (item["longTime"] / 3600).toFixed(1)
                        return useHour < 4 ? useHour : 0
                    }
                case "useHourAbove4":
                    if (!item.isValid) {
                        return null
                    } else {
                        const useHour = (item["longTime"] / 3600).toFixed(1)
                        return useHour >= 4 ? useHour : 0
                    }
                case "tpMax":
                case "tpMin":
                    if (!item.isValid) {
                        return null
                    } else {
                        return item[key] / 10
                    }
                case "humidifyLevel":
                    if (!item.isValid) {
                        return null
                    } else {
                        return parseInt(item[key]) + 1
                    }
                // 漏气
                case "leakageUnder5":
                    if (!item.isValid) {
                        return null
                    } else {
                        const leakage = parseFloat(item["leakage"])
                        return !isNaN(leakage) && leakage < 5 ? leakage : 0
                    }
                case "leakageAbove5":
                    if (!item.isValid) {
                        return null
                    } else {
                        const leakage = parseFloat(item["leakage"])
                        return !isNaN(leakage) && leakage >= 5 ? leakage : 0
                    }
                default:
                    if (!item.isValid) {
                        return null
                    } else {
                        return parseFloat(item[key])
                    }
            }
        })
    }
}

export { StatisticsReportModel };
